<template>
    <transition name="dialog"
                appear>
        <div v-if="!modalStore.showExitAnimation"
             class="modal modal-transition"
             @click="modalStore.setReturnToMobileAppModal(false)">
            <div class="modal-wrapper">
                <div class="modal-content"
                     @click.stop>
                    <div class="text-left">
                        <span class="close-button"
                              @click="modalStore.setReturnToMobileAppModal(false)">&times;</span>
                    </div>
                    <div class="my-auto">
                        <img :src="WALLET_CONNECT_SUCCESS"
                             :alt="getTrans('quests-dashboard.walletConnectedSuccessfullyImage', 'Wallet connected successfully image')">
                        <p class="modal-title">
                            <Translate translationKey="quests-dashboard.walletConnectedSuccessfully"
                                       fallback="Wallet connected successfully!" />
                        </p>
                    </div>
                    <div>
                        <button class="quiz-button--intro"
                                @click="redirectToApp">
                            <Translate translationKey="quests-dashboard.backToApp"
                                       fallback="Back to App" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { getTrans } from '@/js/helpers/getTrans';
    import Translate from '@/js/helpers/Translate.vue';
    import { WALLET_CONNECT_SUCCESS } from '@/js/constants/assets';

    const modalStore = useModalStore();

    function redirectToApp() {
        window.location.href = 'bitdegree://wallet-connect-success';
        modalStore.setReturnToMobileAppModal(false);
    }
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/partials/z-index";
@import "@/sass/partials/breakpoints";

.modal-transition {
    z-index: $modal-transition-max-z-index;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
        justify-content: space-between;
        padding: 48px;
        text-align: center;

        @media (max-width: $md-breakpoint-max-width) {
            padding: 0 16px 16px 16px;
        }
    }
}

.modal-title {
    margin-top: 54px;
    margin-bottom: 40px;
    color: $white;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
}

.quiz-button--intro {
    padding: 0 17px;
    border: 1px solid rgba($white, 0.8);
    box-shadow: 3px 4px 0 0 gray;
    color: $black;
}
</style>
